<template>
    <textarea :value="getValue" class="form-control max-w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 resize-y focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="3" :placeholder="getPlaceHolder" @input="handleChange"></textarea>
</template>

<script>
export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    computed: {
        getPlaceHolder() {
            return this.fieldData.placeholder || ''
        },
        getValue: {
            get() {
                return this.fieldData.value || ''
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            }
        }
    },
    methods: {
        handleChange({target}) {
            this.getValue = target.value
        }
    }
};
</script>

<style>
</style>